import { useLazyQuery } from '@apollo/client';
import {
  DocumentArrowUpIcon,
  PaperAirplaneIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { compareDesc } from 'date-fns';
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { notifications, yourCohorts } from 'queries/programmes';

import { useSession } from 'providers/session';

interface Notification {
  Icon?: any;
  image?: string;
  title: string;
  description: string;
  date: string;
}

interface State {
  createGroup: boolean;
  createProgramme: boolean;
  groupId: any;
  programme: any;
  unit: any;
  callback: any;
  student: any;
  member: any;
  learner: any;
  prefill: any;
  uploadEvidence: boolean;
  changeDue: boolean;
  unitId: any;
  due: any;
  activity: boolean;
}

interface Activity {
  activities: Notification[];
  placeholder: string;
}
export interface Member {
  id: string;
  created_at: string;
  updated_at: string;
  email: string;
  programmeStatus: {
    COMPLETE: number;
    ACTIVE: number;
    IQA: number;
    ASSESSED: number;
  };
  evidenceSummary: {
    completeEvidenceCount: number;
    markedNotCountedCount: number;
    notSubmittedNotCompleteCount: number;
    submittedNotCompleteCount: number;
  };
  completeEvidence: number;
  full_name: string;
}

interface Note {
  id: string;
  internal: boolean;
  note: {
    created_at: string;
    id: string;
    title: string;
    content: string;
  };
}

interface Cohort {
  id: string;
  name: string;
  status: string;
  team: {
    id: string;
    created_at: string;
    updated_at: string;
    type: string;
    status: string;
    member: {
      id: string;
      full_name: string;
      avatar: {
        asset: {
          id: string;
          url: string;
        };
      };
    };
  };
}

export interface Student {
  id: string;
  status: string;
  type: string;
  notes: Note[];
  cohort: Cohort;
  statuses: any[];
  assignments: Assignment[];
  member: {
    id: string;
    full_name: string;
    avatar: Array<{ asset: { url: string } }>;
  };
}

interface Assignment {
  id: string;
  created_at: string;
  status: string;
  unit: {
    title: string;
    description: string;
    active: boolean;
    unit_code: string;
    e_learning_id: string;
    assets: any[];
  };
}

export interface ProgrammeContextValue {
  yourCohorts: any;
  activity: Activity;
  state: State;
  setState: Dispatch<SetStateAction<State>>;
  setActivity: Dispatch<SetStateAction<Activity>>;
  notifications: Notification[];
}

const initialState = {};

const ProgrammeContext = createContext(initialState as ProgrammeContextValue);

function BP({ children }) {
  const { claims } = useSession();

  const [state, setState] = useState({
    createGroup: false,
    callback: null,
    createProgramme: false,
    unit: null,
    changeDue: false,
    groupId: null,
    programme: null,
    unitId: null,
    prefill: null,
    student: null,
    member: null,
    learner: null,
    due: null,
    activity: true,
    uploadEvidence: false,
    evidenceRefetch: null,
  });

  const currentUser = claims['x-hasura-user-id'];

  const [activity, setActivity] = useState<Activity>({
    activities: [],
    placeholder: 'There is no new activity',
  });

  const [loadCohorts, cohortReturnValue] = useLazyQuery(yourCohorts, {
    variables: { userId: currentUser },
    fetchPolicy: 'cache-and-network',
  });

  const [loadNotifcations, notificationsReturnValue] = useLazyQuery(
    notifications,
    {
      variables: {
        id: currentUser,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const {
    called: cohortsCalled,
    error: cohortsError,
    data: cohortsData,
    loading: cohortsLoading,
    refetch: refetchCohorts,
  } = cohortReturnValue;

  const {
    called: notificationsCalled,
    error: notificationsError,
    data: notificationsData,
    loading: notificationsLoading,
    refetch: refetchNotifications,
  } = notificationsReturnValue;

  const n = useMemo(() => {
    const notifications = [];

    if (notificationsData?.cohorts && notificationsData?.cohorts.length > 0) {
      notificationsData?.cohorts.forEach((cohort) => {
        const evidence = cohort?.evidence;

        if (evidence && evidence.length > 0) {
          evidence.forEach((evidence) => {
            notifications.push({
              title: 'Created Evidence',
              description: `<span style="font-weight:600">${evidence.owner?.full_name}</span> created evidence for course <span style="font-weight:600">${evidence?.unit?.title}</span> group`,
              date: evidence.created_at,
              Icon: DocumentArrowUpIcon,
            });

            if (evidence.submitted) {
              notifications.push({
                title: 'Submitted Evidence',
                description: `<span style="font-weight:600">${evidence.owner?.full_name}</span> submitted evidence for course <span style="font-weight:600">${evidence?.unit?.title}</span>`,
                date: evidence.submitted_at,
                Icon: PaperAirplaneIcon,
              });
            }
          });
        }

        cohort.team?.forEach((member) => {
          notifications.push({
            title: 'Member Added',
            description: `<span style="font-weight:600">${member.member.full_name}</span> was added to the <span style="font-weight:600">${cohort.name}</span> group`,
            date: member.created_at,
            Icon: UserIcon,
            image: member.member.avatar[0]?.asset.url,
          });
        });

        cohort.students?.forEach((student) => {
          notifications.push({
            title: 'Student Added',
            description: `<span style="font-weight:600">${student.member.full_name}</span> was added to the ${cohort.name} group by <span style="font-weight:600">${student.addedBy.full_name}</span>`,
            date: student.created_at,
            Icon: UserIcon,
          });
        });
      });
    }

    return notifications.sort((a, b) =>
      compareDesc(new Date(a.date), new Date(b.date)),
    );
  }, [notificationsData?.cohorts]);

  useEffect(() => {
    if (n && n.length > 0) {
      setActivity({
        activities: n,
        placeholder: 'There is no new activity',
      });
    }
  }, [n]);

  const sortedActivities = useMemo(() => {
    const activitiesClone = [...activity.activities];
    return activitiesClone.sort((a, b) =>
      compareDesc(new Date(a.date), new Date(b.date)),
    );
  }, [activity.activities]);

  useEffect(() => {
    loadNotifcations();
    loadCohorts();
  }, []);

  const value: ProgrammeContextValue = useMemo(
    () => ({
      activity: {
        loading: notificationsLoading,
        activities: sortedActivities,
        placeholder: activity.placeholder,
      },
      notifications: n,
      setActivity,
      yourCohorts: cohortsData ? cohortsData.cohorts : [],
      state,
      setState,
    }),
    [
      notificationsLoading,
      sortedActivities,
      activity.placeholder,
      n,
      cohortsData,
      state,
    ],
  );

  return (
    <ProgrammeContext.Provider value={value}>
      {children}
    </ProgrammeContext.Provider>
  );
}

const ProgrammeProvider = BP;

export const useProgramme = () => useContext(ProgrammeContext);

export default ProgrammeProvider;
