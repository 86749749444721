import { gql } from '@apollo/client';

export const getProgrammesList = gql`
  query Programme {
    programmes: programme {
      id
      title
      active
      code
      completeEvidence
      units {
        id
        unit_code
        created_at
        updated_at
        title
        e_learning_id
        assets
        outcomes {
          id
          title
          description
          type
          overall: overall_outcome
          group: outcome_group
        }
      }
      created_at
      updated_at
      cohorts {
        id
        event_id
        due_dates
        studentAggregate: members_aggregate(
          where: { type: { _eq: "STUDENT" } }
        ) {
          aggregate {
            count
          }
        }

        team: members(
          where: {
            _or: [
              { type: { _eq: "TUTOR" } }
              { type: { _eq: "ASSESSOR" } }
              { type: { _eq: "IQA" } }
            ]
          }
        ) {
          id
          type
          member {
            id
            full_name
            avatar: user_assets {
              asset: assetByAsset {
                id
                url
              }
            }
          }
        }
      }
    }
  }
`;
export const programmes = gql`
  query Programme {
    programmes: programme {
      id
      title
      active
      code
      completeEvidence
      units {
        id
        unit_code
        created_at
        updated_at
        title
        e_learning_id
        assets
        outcomes {
          id
          title
          description
          type
          overall: overall_outcome
          group: outcome_group
        }
      }
      created_at
      updated_at
      cohorts {
        id
        event_id
        due_dates
        studentAggregate: members_aggregate(
          where: { type: { _eq: "STUDENT" } }
        ) {
          aggregate {
            count
          }
        }
        students: members(
          where: { _and: [{ type: { _eq: "STUDENT" } }] }
          order_by: { created_at: desc }
        ) {
          id
          created_at
          updated_at
          status
          cohort_id
          cohort {
            team: members(
              where: {
                _or: [
                  { type: { _eq: "TUTOR" } }
                  { type: { _eq: "ASSESSOR" } }
                  { type: { _eq: "IQA" } }
                ]
              }
            ) {
              id
              type
              member {
                id
                full_name
                avatar: user_assets {
                  asset: assetByAsset {
                    id
                    url
                  }
                }
              }
            }
            event_id
          }
          member {
            id
            name: given_name
            surname: family_name
            email
            full_name
          }
        }
        team: members(
          where: {
            _or: [
              { type: { _eq: "TUTOR" } }
              { type: { _eq: "ASSESSOR" } }
              { type: { _eq: "IQA" } }
            ]
          }
        ) {
          id
          type
          member {
            id
            full_name
            avatar: user_assets {
              asset: assetByAsset {
                id
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const insertProgramme = gql`
  mutation CreateProgramme($data: programme_insert_input!) {
    insert_programme_one(object: $data) {
      id
    }
  }
`;

export const updateEvidence = gql`
  mutation updateEvidence($id: uuid!, $data: evidence_set_input!) {
    evidence: update_evidence_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;

export const getUnitEvidence = gql`
  query getUnitEvidence($unitId: uuid!) {
    assignments: evidence(
      where: {
        _and: [{ unit_id: { _eq: $unitId } }, { assignment: { _eq: true } }]
      }
    ) {
      id
      submitted
      submitted_at
      created_at
      updated_at
      asset_id
      report_id
      revision
      report {
        id
        created_at
      }
      form_id
      form {
        id
        created_at
        form: formByForm {
          title
        }
      }
      cohort {
        due_dates
        students: members(
          where: { _and: [{ type: { _eq: "STUDENT" } }] }
          order_by: { created_at: desc }
        ) {
          id
          created_at
          updated_at
          status
          member {
            id
            full_name
          }
        }
        team: members(
          where: {
            _or: [
              { type: { _eq: "TUTOR" } }
              { type: { _eq: "ASSESSOR" } }
              { type: { _eq: "IQA" } }
            ]
          }
        ) {
          id
          created_at
          updated_at
          type
          status
          member {
            id
            full_name
            avatar: user_assets {
              asset: assetByAsset {
                id
                url
              }
            }
          }
        }
      }
      unit {
        unit_code
        title
      }
      asset {
        id
        url
        props
      }
      owner {
        id
        full_name
      }
      statuses {
        status
      }
      outcomes {
        id
        outcome {
          id
          title
          description
          type
          overall: overall_outcome
          group: outcome_group
          outcomes {
            id
            title
            description
            type
            markings {
              id
              created_at
              updated_at
              outcome_id
              member_id
              evidence_id
              markedBy {
                id
              }
            }
            overall: overall_outcome
            group: outcome_group
          }
        }
      }
    }
    evidence(
      where: {
        _and: [{ unit_id: { _eq: $unitId } }, { assignment: { _eq: false } }]
      }
    ) {
      id
      submitted
      submitted_at
      created_at
      updated_at
      asset_id
      report_id
      report {
        id
        created_at
      }
      form_id
      form {
        id
        created_at
        form: formByForm {
          title
        }
      }
      cohort {
        due_dates
        students: members(
          where: { _and: [{ type: { _eq: "STUDENT" } }] }
          order_by: { created_at: desc }
        ) {
          id
          created_at
          updated_at
          status
          member {
            id
            full_name
          }
        }
        team: members(
          where: {
            _or: [
              { type: { _eq: "TUTOR" } }
              { type: { _eq: "ASSESSOR" } }
              { type: { _eq: "IQA" } }
            ]
          }
        ) {
          id
          created_at
          updated_at
          type
          status
          member {
            id
            full_name
            avatar: user_assets {
              asset: assetByAsset {
                id
                url
              }
            }
          }
        }
      }
      unit {
        unit_code
        title
      }
      asset {
        id
        url
        props
      }
      owner {
        id
        full_name
      }
      statuses {
        status
      }
      outcomes {
        id
        outcome {
          id
          title
          description
          type
          overall: overall_outcome
          group: outcome_group
          outcomes {
            id
            title
            description
            type
            markings {
              id
              created_at
              updated_at
              outcome_id
              member_id
              evidence_id
              markedBy {
                id
              }
            }
            overall: overall_outcome
            group: outcome_group
          }
        }
      }
    }
  }
`;

export const getUnit = gql`
  query Unit($id: uuid!) {
    unit: programme_unit_by_pk(id: $id) {
      id
      created_at
      completeEvidence
      updated_at
      title
      description
      active
      unit_code
      e_learning_id
      assets
      programme_id
      programme {
        id
        title
        completeEvidence
        active
        code
        qualification_number
        created_at
        updated_at
      }
      outcomes {
        id
        title
        description
        group: outcome_group
        overall: overall_outcome
        type
        evidence {
          id
          created_at
          updated_at
          evidence {
            submitted
            submitted_at
            asset_id
            asset {
              id
              url
              props
            }
            statuses {
              status
            }
          }
        }
      }
    }
  }
`;

export const getProgramme = gql`
  query Programme($id: uuid!) {
    programme: programme_by_pk(id: $id) {
      id
      title
      completeEvidence
      active
      code
      level
      qualification_number
      created_at
      updated_at
      units {
        id
        created_at
        updated_at
        title
        description
        active
        unit_code
        e_learning_id
        assets
        assignment
        assignment_template
        outcomes(order_by: { created_at: asc }) {
          id
          title
          description
          outcome_group
          overall_outcome
          type
          assignment_options
        }
        evidence {
          id
          archived
          submitted
          submitted_at
          created_at
          updated_at
          asset_id
          owner {
            id
            full_name
          }
        }
      }
      cohorts {
        id
        due_dates
        name
        status
        created_by
        created_at
        updated_at
        event_id
        createdBy {
          id
          full_name
        }
        event {
          id
          start
          end
          detail {
            title
          }
        }
        studentAggregate: members_aggregate(
          where: { type: { _eq: "STUDENT" } }
        ) {
          aggregate {
            count
          }
        }
        team: members(
          where: {
            _or: [
              { type: { _eq: "TUTOR" } }
              { type: { _eq: "ASSESSOR" } }
              { type: { _eq: "IQA" } }
            ]
          }
        ) {
          id
          created_at
          updated_at
          type
          member {
            id
            full_name
            avatar: user_assets {
              asset: assetByAsset {
                id
                url
              }
            }
          }
        }
        students: members(where: { _or: [{ type: { _eq: "STUDENT" } }] }) {
          id
          created_at
          updated_at
          member {
            id
            full_name
          }
        }
      }
    }
  }
`;

export const notifications = gql`
  query notifications($id: uuid!) {
    cohorts: cohort(where: { members: { member: { id: { _eq: $id } } } }) {
      id
      name
      status
      created_at
      programme {
        title
      }
      evidence {
        id
        submitted
        submitted_at
        created_at
        updated_at
        asset_id
        unit {
          title
        }
        owner {
          id
          full_name
        }
      }
      students: members(
        where: { _and: [{ type: { _eq: "STUDENT" } }] }
        limit: 5
        order_by: { created_at: desc }
      ) {
        id
        created_at
        updated_at
        status
        member {
          id
          full_name
        }
        addedBy {
          id
          full_name
        }
      }
      team: members(
        where: {
          _or: [
            { type: { _eq: "TUTOR" } }
            { type: { _eq: "ASSESSOR" } }
            { type: { _eq: "IQA" } }
          ]
        }
        limit: 5
        order_by: { created_at: desc }
      ) {
        id
        created_at
        member {
          id
          full_name
          avatar: user_assets {
            asset: assetByAsset {
              id
              url
            }
          }
        }
      }
    }
  }
`;

export const yourCohorts = gql`
  query yourCohorts($userId: uuid!) {
    cohorts: cohort(where: { members: { member_id: { _eq: $userId } } }) {
      id
      name
      status
      created_at
      updated_at
      event {
        id
        title
        detail {
          title
        }
        end
        start
      }
      students: members(where: { _or: [{ type: { _eq: "STUDENT" } }] }) {
        id
        created_at
        updated_at
        status
        member {
          id
          full_name
        }
        addedBy {
          id
          full_name
        }
      }
      team: members(
        where: {
          _or: [
            { type: { _eq: "TUTOR" } }
            { type: { _eq: "ASSESSOR" } }
            { type: { _eq: "IQA" } }
          ]
        }
      ) {
        id
        member {
          id
          full_name
          avatar: user_assets {
            asset: assetByAsset {
              id
              url
            }
          }
        }
      }
      programme {
        id
        title
      }
      createdBy {
        id
        full_name
      }
    }
  }
`;

export const cohorts = gql`
  query Cohorts($where: cohort_bool_exp) {
    cohorts: cohort(where: $where, order_by: { created_at: desc }) {
      id
      name
      status
      created_at
      updated_at
      event {
        id
        title
        detail {
          title
        }
        end
        start
      }
      students_aggregate: members_aggregate(
        where: { _or: [{ type: { _eq: "STUDENT" } }] }
      ) {
        aggregate {
          count
        }
      }
      team_aggregate: members_aggregate(
        where: {
          _or: [
            { type: { _eq: "TUTOR" } }
            { type: { _eq: "ASSESSOR" } }
            { type: { _eq: "IQA" } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      programme {
        id
        title
      }
      createdBy {
        id
        full_name
      }
    }
  }
`;

export const insertCohort = gql`
  mutation insertCohort($data: cohort_insert_input!) {
    cohort: insert_cohort_one(object: $data) {
      id
    }
  }
`;

export const updateCohort = gql`
  mutation updateCohort($id: uuid!, $data: cohort_set_input!) {
    cohort: update_cohort_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;

export const updateCohortMember = gql`
  mutation updateCohortMember($id: uuid!, $data: cohort_member_set_input!) {
    cohort: update_cohort_member_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;

export const deleteCohortMember = gql`
  mutation deleteCohortMember($id: uuid!) {
    cohortMember: delete_cohort_member_by_pk(id: $id) {
      id
    }
  }
`;

export const deleteCohortMembers = gql`
  mutation deleteCohortMembers($ids: [uuid!]!) {
    cohortMember: delete_cohort_member(where: { id: { _in: $ids } }) {
      affected_rows
    }
  }
`;

export const cohort = gql`
  query Cohort($id: uuid!) {
    cohort: cohort_by_pk(id: $id) {
      id
      name
      completeEvidence
      created_at
      updated_at
      evidence {
        owner {
          full_name
        }
        revisionBy {
          full_name
        }
        unit {
          title
        }
        accepted_at
        accepted
        revision
        revision_at
      }
      due_dates
      notes {
        id
        internal
        note {
          created_at
          id
          title
          content
        }
      }
      team: members(
        where: {
          _or: [
            { type: { _eq: "TUTOR" } }
            { type: { _eq: "ASSESSOR" } }
            { type: { _eq: "IQA" } }
          ]
        }
      ) {
        id
        created_at
        updated_at
        type
        status
        member {
          id
          full_name
          avatar: user_assets {
            asset: assetByAsset {
              id
              url
            }
          }
        }
      }
      students: members(where: { _or: [{ type: { _eq: "STUDENT" } }] }) {
        id
        created_at
        updated_at
        status
        evidence_complete
        member {
          id
          full_name
          evidenceSummary
          completeEvidence
        }
        addedBy {
          id
          full_name
        }
      }
      event {
        id
        title
        start
        end
        detail {
          title
        }
      }
      programme {
        id
        title
        units {
          id
          created_at
          active
          code: unit_code
          title
          description
          assets
          e_learning_id
          outcomes {
            id
            title
            description
            group: outcome_group
            overall: overall_outcome
            type
          }
        }
      }
    }
  }
`;

export const addCohort = gql`
  mutation CreateCohort($data: cohort_insert_input!) {
    cohort: insert_cohort_one(object: $data) {
      id
    }
  }
`;

export const insertCohortMembers = gql`
  mutation insertCohortMember($data: [cohort_member_insert_input!]!) {
    insert_cohort_member(objects: $data) {
      affected_rows
    }
  }
`;

export const cohortMembers = gql`
  query CohortMembers($where: cohort_member_bool_exp) {
    members: cohort_member(where: $where) {
      id
      created_at
      updated_at
      type
      status
      cohort {
        id
        name
        status
        due_dates
        programme {
          code
          title
          units {
            id
            created_at
            updated_at
            title
            description
            active
            unit_code
            e_learning_id
            assets
          }
        }
      }
      assignments {
        id
        created_at
        statuses {
          id
          created_at
          status
        }
        unit {
          title
          description
          active
          unit_code
          e_learning_id
          assets
        }
      }
      member {
        id
        evidenceSummary
        full_name
        avatar: user_assets {
          asset: assetByAsset {
            id
            url
          }
        }
      }
    }
  }
`;

export const getCohortMembers = gql`
  query CohortMembers {
    members: user(
      order_by: { given_name: asc }
      where: {
        _and: [
          { is_in_programme: { _eq: true } }
          { cohort_members: { type: { _eq: "STUDENT" } } }
        ]
      }
    ) {
      id
      email
      programmeStatus
      created_at
      updated_at
      full_name
      name: given_name
      surname: family_name
      completeEvidence
      cohort_members {
        cohort_id
        cohort {
          id
          programme_id
          event_id
          team: members(
            where: {
              _or: [
                { type: { _eq: "TUTOR" } }
                { type: { _eq: "ASSESSOR" } }
                { type: { _eq: "IQA" } }
              ]
            }
          ) {
            id
            type
          }
        }
      }
    }
  }
`;

export const getEvidenceOutcomes = gql`
  query getEvidenceOutcome($evidenceId: uuid) {
    outcomes: evidence_outcome(where: { evidence_id: { _eq: $evidenceId } }) {
      id
      outcome {
        id
        title
        description
        type
        overall: overall_outcome
        outcomes {
          id
          title
          overall: overall_outcome
          markings {
            id
            member_id
          }
        }
      }
    }
  }
`;

export const getAssignmentVersion = gql`
  query getAssignmentVersion($unitId: uuid!, $userId: uuid!) {
    evidenceAggregate: evidence_aggregate(
      where: {
        _and: [{ unit_id: { _eq: $unitId } }, { owner_id: { _eq: $userId } }]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const getAssignment = gql`
  query getAssignment($id: uuid!) {
    assignment: evidence_by_pk(id: $id) {
      id
      created_at
      submitted
      submitted_at
      archived
      content
      assignment
      revision
      version
      unit_id
      owner_id
      owner {
        full_name
      }
      statuses(order_by: { created_at: desc }) {
        id
        status
        created_at
        createdBy {
          id
          full_name
        }
      }
      notes {
        internal
        id
        note {
          created_at
          updated_at
          data
          id
          user
          employee {
            full_name
            avatar: user_assets {
              asset: assetByAsset {
                id
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const getMarkings = gql`
  query getMarkings($evidenceId: uuid!) {
    markings: evidence_marking(where: { evidence_id: { _eq: $evidenceId } }) {
      id
      created_at
      updated_at
      outcome_id
      member_id
      evidence_id
      markedBy {
        id
      }
    }
  }
`;

export const getEvidence = gql`
  query Evidence($id: uuid!) {
    evidence: evidence_by_pk(id: $id) {
      id
      created_at
      submitted
      submitted_at
      archived
      assignment
      revision
      revision_at
      accepted
      accepted_at
      markings {
        id
        created_at
        updated_at
        outcome_id
        member_id
        evidence_id
        markedBy {
          id
        }
      }
      outcomes(where: { outcome: { overall_outcome: { _eq: true } } }) {
        id
        outcome {
          id
          title
          type
          overall: overall_outcome
          outcomes {
            id
            title
            overall: overall_outcome
            assignment_options
          }
        }
      }
      cohort {
        due_dates
        students: members(
          where: { _and: [{ type: { _eq: "STUDENT" } }] }
          order_by: { created_at: desc }
        ) {
          id
          created_at
          updated_at
          status
          member {
            id
            full_name
          }
        }
        team: members(
          where: {
            _or: [
              { type: { _eq: "TUTOR" } }
              { type: { _eq: "ASSESSOR" } }
              { type: { _eq: "IQA" } }
            ]
          }
        ) {
          id
          created_at
          updated_at
          type
          status
          member {
            id
            full_name
            avatar: user_assets {
              asset: assetByAsset {
                id
                url
              }
            }
          }
        }
      }
      unit {
        title
        description
        active
        unit_code
        assignment
        assignment_template
        e_learning_id
        assets
        outcomes {
          id
          title
          description
          type
          overall: overall_outcome
        }
      }
      owner {
        id
        full_name
      }
      createdBy {
        id
        full_name
      }
      asset_id
      asset {
        id
        url
        props
      }
      report_id
      report {
        id
        created_at
        event {
          id
          detail {
            id
            title
          }
        }
      }
      form_id
      form {
        id
        created_at
        form: formByForm {
          title
        }
        createdAt: created_at
        updatedAt: updated_at
        formByForm {
          id
          title
          content
          formQuestions(
            where: {
              _and: [
                { questionByQuestion: { archived: { _eq: false } } }
                { archived: { _eq: false } }
              ]
            }
          ) {
            id
            order
            properties
            question: questionByQuestion {
              label
              type
            }
          }
        }
        responsesByResponses {
          id
          complete
          responses
        }
      }
      statuses(order_by: { created_at: desc }) {
        id
        status
        created_at
        createdBy {
          id
          full_name
        }
      }
    }
  }
`;

export const getMemberEvidence = gql`
  query getMemberEvidence($userId: uuid!, $assignment: Boolean!) {
    evidence(
      order_by: { created_at: desc }
      where: {
        _and: [
          { owner_id: { _eq: $userId } }
          { assignment: { _eq: $assignment } }
        ]
      }
    ) {
      id
      created_at
      archived
      submitted
      submitted_at
      updated_at
      revision
      unit {
        title
        unit_code
      }
      outcomes {
        id
        outcome {
          id
          title
          description
          type
          overall: overall_outcome
          group: outcome_group
        }
      }
      asset_id
      asset {
        id
        url
        props
      }
      report_id
      report {
        id
        created_at
      }
      form_id
      form {
        id
        created_at
        form: formByForm {
          title
        }
      }
      statuses(order_by: { created_at: desc }) {
        id
        status
      }
    }
  }
`;

export const getCohortMember = gql`
  query cohortMember($id: uuid!) {
    user: user_by_pk(id: $id) {
      id
      created_at
      completeEvidence
      updated_at
      id
      evidenceSummary
      full_name
      name: given_name
      surname: family_name
      email
      avatar: user_assets {
        asset: assetByAsset {
          id
          url
        }
      }
      assignment_aggregate: evidence_aggregate(
        where: { assignment: { _eq: true } }
      ) {
        aggregate {
          count
        }
      }
      evidence_aggregate(where: { assignment: { _eq: false } }) {
        aggregate {
          count
        }
      }
      cohort_members {
        id
        created_at
        updated_at
        type
        status
        evidence_complete
        member {
          id
          full_name
        }
        addedBy {
          id
          full_name
        }
        notes {
          id
          internal
          note {
            created_at
            id
            title
            content
          }
        }
        cohort {
          id
          name
          status
          due_dates
          team: members(
            where: {
              _or: [
                { type: { _eq: "TUTOR" } }
                { type: { _eq: "ASSESSOR" } }
                { type: { _eq: "IQA" } }
              ]
            }
          ) {
            id
            created_at
            updated_at
            type
            status
            member {
              id
              full_name
              avatar: user_assets {
                asset: assetByAsset {
                  id
                  url
                }
              }
            }
          }
          programme {
            id
            code
            active
            title
            created_at
            units {
              id
              created_at
              updated_at
              title
              description
              active
              unit_code
              e_learning_id
              assets
            }
          }
        }
      }
    }
  }
`;

export const cohortMember = gql`
  query CohortMembers($id: uuid!) {
    cohortMember: cohort_member_by_pk(id: $id) {
      id
      created_at
      updated_at
      type
      status
      notes {
        id
        internal
        note {
          created_at
          id
          title
          content
        }
      }
      cohort {
        id
        name
        status
        due_dates
        team: members(
          where: {
            _or: [
              { type: { _eq: "TUTOR" } }
              { type: { _eq: "ASSESSOR" } }
              { type: { _eq: "IQA" } }
            ]
          }
        ) {
          id
          created_at
          updated_at
          type
          status
          member {
            id
            full_name
            avatar: user_assets {
              asset: assetByAsset {
                id
                url
              }
            }
          }
        }
        programme {
          code
          title
          units {
            id
            created_at
            updated_at
            title
            description
            active
            unit_code
            e_learning_id
            assets
          }
        }
      }
      assignments {
        id
        created_at
        statuses {
          id
          created_at
          status
        }
        unit {
          title
          description
          active
          unit_code
          e_learning_id
          assets
        }
      }
      member {
        id
        full_name
        avatar: user_assets {
          asset: assetByAsset {
            id
            url
          }
        }
      }
    }
  }
`;

export const insertCohortNote = gql`
  mutation insertCohortNote($data: cohort_note_insert_input!) {
    note: insert_cohort_note_one(object: $data) {
      id
    }
  }
`;

export const insertEvidenceStatus = gql`
  mutation insertEvidenceStatus($data: [evidence_status_insert_input!]!) {
    status: insert_evidence_status(objects: $data) {
      affected_rows
    }
  }
`;

export const insertEvidenceMarking = gql`
  mutation insertEvidenceMarking($data: evidence_marking_insert_input!) {
    marking: insert_evidence_marking_one(object: $data) {
      id
    }
  }
`;

export const insertEvidenceMarkings = gql`
  mutation insertEvidenceMarkings($data: [evidence_marking_insert_input!]!) {
    marking: insert_evidence_marking(objects: $data) {
      affected_rows
    }
  }
`;

export const deleteEvidenceMarkings = gql`
  mutation deleteEvidenceMarkings($where: evidence_marking_bool_exp!) {
    marking: delete_evidence_marking(where: $where) {
      affected_rows
    }
  }
`;
export const deleteEvidenceOutcomes = gql`
  mutation deleteEvidenceOutcomes($where: evidence_outcome_bool_exp!) {
    outcome: delete_evidence_outcome(where: $where) {
      affected_rows
    }
  }
`;
export const insertEvidenceOutcomes = gql`
  mutation insertEvidenceOutcomes($data: [evidence_outcome_insert_input!]!) {
    insert_evidence_outcome(objects: $data) {
      affected_rows
    }
  }
`;

export const deleteEvidenceMarking = gql`
  mutation deleteEvidenceMarking($id: uuid!) {
    marking: delete_evidence_marking_by_pk(id: $id) {
      id
    }
  }
`;
export const deleteEvidenceNote = gql`
  mutation deleteEvidenceNote($id: uuid!) {
    note: delete_evidence_note_by_pk(id: $id) {
      id
    }
  }
`;

export const addEvidenceNote = gql`
  mutation addEvidenceNote($data: evidence_note_insert_input!) {
    insert_evidence_note_one(object: $data) {
      id
    }
  }
`;

export const insertEvidence = gql`
  mutation insertEvidence($data: evidence_insert_input!) {
    insert_evidence_one(object: $data) {
      id
    }
  }
`;

export const insertEvidenceOutcome = gql`
  mutation insertEvidenceOutcome($data: evidence_outcome_insert_input!) {
    insert_evidence_outcome_one(object: $data) {
      id
    }
  }
`;

export const insertCohortMemberNote = gql`
  mutation insertCohortMemberNote($data: cohort_member_note_insert_input!) {
    note: insert_cohort_member_note_one(object: $data) {
      id
    }
  }
`;

export const deleteCohortMemberNote = gql`
  mutation deleteCohortMemberNote($id: uuid!) {
    note: delete_cohort_member_note_by_pk(id: $id) {
      id
    }
  }
`;

export const deleteCohortNote = gql`
  mutation deleteCohortNote($id: uuid!) {
    note: delete_cohort_note_by_pk(id: $id) {
      id
    }
  }
`;

export const insertUnit = gql`
  mutation CreateUnit($data: programme_unit_insert_input!) {
    insert_programme_unit_one(object: $data) {
      id
    }
  }
`;

export const insertOutcome = gql`
  mutation CreateOutcome($data: programme_unit_outcome_insert_input!) {
    insert_programme_unit_outcome_one(object: $data) {
      id
    }
  }
`;

export const updateProgramme = gql`
  mutation updateProgramme($id: uuid!, $data: programme_set_input!) {
    update_programme_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;

export const updateUnit = gql`
  mutation updateUnit($id: uuid!, $data: programme_unit_set_input!) {
    update_programme_unit_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;

export const updateOutcome = gql`
  mutation updateOutcome($id: uuid!, $data: programme_unit_outcome_set_input!) {
    update_programme_unit_outcome_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;

export const deleteUnit = gql`
  mutation deletUnit($id: uuid!) {
    delete_programme_unit_by_pk(id: $id) {
      id
    }
  }
`;

export const deleteOutcome = gql`
  mutation deletOutcome($id: uuid!) {
    delete_programme_unit_outcome_by_pk(id: $id) {
      id
    }
  }
`;

export const getProgrammeFromCohortID = gql`
  query getProgrammeFromCohortID($id: uuid!) {
    programme(where: { cohorts: { id: { _eq: $id } } }) {
      id
    }
  }
`;

export const getProgrammeFromUnitID = gql`
  query getProgrammeFromUnitID($id: uuid!) {
    programme(where: { units: { id: { _eq: $id } } }) {
      id
    }
  }
`;

export const getStudentFromEvidenceID = gql`
  query getStudentFromEvidenceID($id: uuid!) {
    evidence_by_pk(id: $id) {
      owner_id
    }
  }
`;

export const getPendingAssignmentStats = gql`
  query AssignmentDetails {
    all: evidence_aggregate(
      where: { _and: [{ assignment: { _eq: true }, submitted: { _eq: true } }] }
    ) {
      aggregate {
        count
      }
    }
    complete: evidence_aggregate(
      where: {
        _and: [
          {
            assignment: { _eq: true }
            submitted: { _eq: true }
            accepted: { _eq: true }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    pending: evidence_aggregate(
      where: {
        _and: [
          { assignment: { _eq: true } }
          { accepted: { _eq: false } }
          { submitted: { _eq: true } }
          { revision: { _eq: false } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const getPendingAssignments = gql`
  query AssignmentDetails($limit: Int = 20, $offset: Int = 0) {
    assignments: evidence(
      where: {
        _and: [
          { assignment: { _eq: true } }
          { accepted: { _eq: false } }
          { submitted: { _eq: true } }
          { revision: { _eq: false } }
        ]
      }
      limit: $limit
      offset: $offset
    ) {
      id
      archived
      accepted
      submitted
      submitted_at
      updated_at
      unit {
        id
        title
        unit_code
      }
      owner {
        id
        name: given_name
        surname: family_name
      }
      statuses {
        id
        status
      }
      created_at
    }
  }
`;

export const getPendingEvidence = gql`
  query AssignmentDetails($limit: Int = 20, $offset: Int = 0) {
    assignments: evidence(
      where: {
        _and: [
          { assignment: { _eq: false } }
          { accepted: { _eq: false } }
          { submitted: { _eq: true } }
          { revision: { _eq: false } }
        ]
      }
      limit: $limit
      offset: $offset
    ) {
      id
      archived
      submitted
      outcomesAggregate: outcomes_aggregate {
        aggregate {
          count
        }
      }
      unit {
        id
        title
        unit_code
      }
      owner {
        id
        name: given_name
        surname: family_name
      }
      statuses {
        id
        status
      }
      created_at
    }
  }
`;

export const getPendingEvidenceStats = gql`
  query AssignmentDetails {
    all: evidence_aggregate(
      where: {
        _and: [{ assignment: { _eq: false }, submitted: { _eq: true } }]
      }
    ) {
      aggregate {
        count
      }
    }
    complete: evidence_aggregate(
      where: {
        _and: [
          {
            assignment: { _eq: false }
            submitted: { _eq: true }
            accepted: { _eq: true }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    pending: evidence_aggregate(
      where: {
        _and: [
          { assignment: { _eq: false } }
          { accepted: { _eq: false } }
          { submitted: { _eq: true } }
          { revision: { _eq: false } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const getEvidenceNotes = gql`
  query getEvidenceNotes(
    $where: evidence_note_bool_exp!
    $limit: Int
    $offset: Int
    $order_by: [evidence_note_order_by!]
  ) {
    notes: evidence_note(
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      internal
      id
      note {
        created_at
        updated_at
        data
        id
        user
        employee {
          full_name
          avatar: user_assets {
            asset: assetByAsset {
              id
              url
            }
          }
        }
      }
    }
  }
`;
