// import 'utils/wdyr'
import '@stripe/terminal-js';
import Amplify from 'aws-amplify';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';

import SessionProvider, { useSession } from 'providers/session';

import ProgrammeProvider from '../components/programme/provider';
import '../styles/index.css';

const OrganizationProvider = dynamic(() => import('providers/organization'));
const UIProvider = dynamic(() => import('providers/ui'));
const BaseProvider = dynamic(() => import('providers/base'));
const CallProvider = dynamic(() => import('providers/calls'));

Amplify.configure({
  aws_project_region: process.env.NEXT_PUBLIC_COGNITO_REGION,
  aws_cognito_region: process.env.NEXT_PUBLIC_COGNITO_REGION,
  aws_user_pools_id: process.env.NEXT_PUBLIC_USER_POOL,
  aws_user_pools_web_client_id: process.env.NEXT_PUBLIC_CLIENT_ID,
  aws_cognito_identity_pool_id: process.env.NEXT_PUBLIC_COGNITO_ID_POOL,
});

export function reportWebVitals(metric) {
  // @ts-ignore
  window.gtag &&
    window.gtag('event', metric.name, {
      event_category:
        metric.label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
      value: Math.round(
        metric.name === 'CLS' ? metric.value * 1000 : metric.value,
      ),
      event_label: metric.id,
      non_interaction: true,
    });
}

const pathWhitelist = [
  '/sign-in',
  '/register',
  '/forgot-password',
  '/reset-password',
];

const Providers = ({ children }) => (
  <OrganizationProvider>
    <BaseProvider>
      <UIProvider>
        <CallProvider>{children}</CallProvider>
      </UIProvider>
    </BaseProvider>
  </OrganizationProvider>
);

function Internal({ Component, pageProps }) {
  const session = useSession();
  const router = useRouter();

  if (
    (session.user && pathWhitelist.includes(router.pathname)) ||
    router.pathname === '/'
  ) {
    router.push('/dashboard');
  }

  if (router.pathname.startsWith('/programme')) {
    return (
      <Providers>
        <ProgrammeProvider>
          <Component {...pageProps} />
        </ProgrammeProvider>
      </Providers>
    );
  }

  return (
    <Providers>
      <Component {...pageProps} />
    </Providers>
  );
}

function GetManaged({ Component, pageProps }) {
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
        />
      </Head>
      <Script strategy="afterInteractive" id="clarity-sc">
        {`(function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
              })(window, document, "clarity", "script", "lv6d3gc1vi");`}
      </Script>
      <Script src="https://js.stripe.com/terminal/v1/" />
      <SessionProvider Component={Component} pageProps={pageProps}>
        <Internal Component={Component} pageProps={pageProps} />
      </SessionProvider>
    </>
  );
}

export default GetManaged;
